export class lazyCSS {
  constructor() {
    this.loads = true;

    this.onResize = () => {
      this.setRWD();
    };

    this.onInit();
  }

  onInit() {
    this.setRWD();
    window.addEventListener('resize', this.onResize);
  }

  setRWD() {
    if (!this.loads) return;

    if (!isRWD(681)) {
      this.loads = null;
      window.removeEventListener('resize', this.onResize);
      addCSS('css/hd.css', {cb: () => {
        fireEv(document, 'hd');
      }});
      this.onResize = null;
    }
  }
}