// ======== Datepicker =========
import flatpickr from "flatpickr";
import {request} from "../global";

class formApp {
  constructor() {
    if (!this.setVars()) return;
    this.setEvents();
  }

  setVars() {
    this.form = document.body.querySelector('[data-form]');
    if (!this.form) return false;

    this.close = this.form.querySelector('[data-close]');
    if (!this.close) return false;

    this.inputs = this.form.querySelectorAll('input,select,textarea');
    if (!this.inputs.length) return false;

    this.btn = this.form.parentNode.querySelector('button[data-submit]');
    if (!this.btn) return false;

    this.dates = this.form.getElementsByClassName('date');
    if (!this.dates.length) return false;

    this.checkbox = this.form.querySelectorAll('input[type="checkbox"]');

    this.obj = {
      data: {},
      api_url: 'api/',
      appList: ['mMedica (Asseco)',
        'AMMS (Asseco)',
        'DrEryk (DrEryk)',
        'DrWidget (MyDr)',
        'Serum (KAMSOFT)',
        'egabinet (Kliniki.pl)',
        'e-recepta (System e-zdrowie (P1))',
        'OptiMed24 (COMARCH)',
        'MP Gabinet Lekarski (Medycyna Praktyczna)',
        'Medicus (INFOTEL Software)',
        'eZOZ (Biostat)',
        'Medfile (Axel Springer)',
        'Znany Lekarz',
        'EuroSoft',
        'Medlink (Neuca)',
        'Mediporta',
        'KS-PPS (KAMSOFT)',
        'CGM Clinimed (CGM)',
        'Riget Software (RIDGET)',
        'Eskulap (KONSULTAN IT)',
        'BlueNote',
        'KS-Somed (KAMSOFT)',
        'MEDIPORT (KAMSOFT)',
        'LAB-BIT (LAB-BIT Software s.a)',
        'KS-MEDIS (KAMSOFT)',
        'Mediqus GABOS (software)',
        'MedicalData',
        'Sonomed',
        'Promed',
        'PROG-MED',
        'OpenCARE',
        'Clininet HIS',
        'MEDI-PORTAL']
    };

    this.obj.appList.sort((a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return true;
  }

  setEvents() {
    String.prototype.replaceAt = function(index, replacement) {
      return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }
    this.initDateInput();

    this.checkbox[0].addEventListener('click', this.obj.evCheckAll = (e) => {
      each(this.checkbox, (key, val) => {
        if (key >= 1 && e.target.checked) val.checked = true;
        else if (key >= 1 && !e.target.checked) val.checked = false;
      })
    })

    this.btn.addEventListener('click', this.obj.evSubmit = () => {
      this.fnSubmit();
    })

    this.close.addEventListener('click', this.obj.evCloseList = () => {
      this.obj.box && del(this.obj.box), this.close.classList.add('hide'), this.inputs[0].value = '';
    })

    this.inputs[8].addEventListener('keyup', (e) => {
      this.filterPostal(e.target);
    })

    this.errors = [];

    each(this.inputs, (key, val) => {
      val.addEventListener('keyup', () => {
        this.validate(val);
        if (!parseInt(key)) this.renderList(val);
      })
    });
  }

  filterPostal(el) {
    if(el.value.length < 3 || el.value.indexOf('-') > -1) return;
    el.value = el.value.replaceAt(2, `-${el.value.charAt(2)}`);
  }

  reset_errors() {
    let errors = this.form.querySelectorAll('small.error')
    each(errors, (key, val) => {
      del(val);
    })
  }

  renderList(el) {
    del(this.obj.box)
    if (el.value.length < parseInt(el.dataset.min)) return this.close.classList.add('hide');
    this.close.classList.remove('hide');
    this.obj.box = render(el.parentNode, `<div class="row box z600 abs left0 mt0 bg--w">`);
    each(this.obj.appList, (key, val) => {
      if (val.toLowerCase().indexOf(el.value.toLowerCase()) > -1) {
        let btn = render(this.obj.box, `<div class="el">${val}</div>`);
        btn.addEventListener('click', () => {
          el.value = val;
          del(this.obj.box);
        })
      }
    })
    if (!this.inputs[0].value.length) del(this.obj.box), this.close.classList.add('hide');
    //if (!this.obj.box.children.length) del(this.obj.box), this.close.classList.add('hide');
  }

  validate(val = false) {
    this.reset_errors();
    this.errors = [];
    val ? this.checkErrors(val) : each(this.inputs, (key, val) => {
      this.checkErrors(val);
    })
    return this.errors.length;
  }

  checkErrors(val) {
    let min = parseInt(val.dataset.min) || 0, max = parseInt(val.dataset.max) || 150;
    switch (val.name) {
      case 'npwz':
        !is_npwz(val.value) ? this.addError(val, 'Nieprawidłowy NPWZ') : this.removeError(val);
        break;
      case 'tel':
        !is_tel(val.value) ? this.addError(val, 'Nieprawidłowy nr telefonu') : this.removeError(val);
        break;
      case 'postal':
        !is_zip(val.value) ? this.addError(val, 'Nieprawidłowy kod pocztowy') : this.removeError(val);
        break;
      default:
        ((val.classList.contains('req') && !val.checked && val.type === 'checkbox') || (val.value.length < min) || (val.value.length > max)) ? this.addError(val) : this.removeError(val);
    }
  }

  addError(val, error = '') {
    if (val.type !== 'checkbox') render(val.parentNode.parentNode, `<small class="error inbl c--red">${error.length ? error : val.dataset.error || `Uzupełnij wymagane pole`}</small>`)
    this.errors.push(val.name);
    val.classList.add('error');
  }

  removeError(val) {
    this.obj.data[val.name] = val.type === 'checkbox' ? val.checked : val.value;
    val.classList.remove('error');
  }

  fnSubmit() {
    sc(this.form);
    this.validate();
    !this.errors.length && request(this.obj.api_url, JSON.stringify(this.obj.data), {
      cb: (xhr) => {
        xhr.json().then(json => {
          if(json.error) return render(document.body, `
        <div class="abs left0 top0 bottom0 right0 auto bg--w shad z900" style="border-radius: 25px;padding: 1em 2em;max-width:100%;width:600px;height:10em">
            <img onclick="del(this.parentNode)" class="abs cur-pointer right20 top20" src="img/close.png" alt="X">
            <h3 style="color:#FF0000FF;margin-top:2.8em;font-size:1.2em">${json.error}</h3>
            </div>`);
          window.location.href = 'thx';
        })
      }
    })
  }

  initDateInput() {
    flatpickr(this.dates[1], {
      locale: {
        weekdays: {
          shorthand: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
          longhand: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ]
        },
        months: {
          shorthand: [
            "Sty",
            "Lut",
            "Mar",
            "Kwi",
            "Maj",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Paź",
            "Lis",
            "Gru",
          ],
          longhand: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ]
        },
        rangeSeparator: " do ",
        weekAbbreviation: "tydz.",
        scrollTitle: "Przewiń, aby zwiększyć",
        toggleTitle: "Kliknij, aby przełączyć",
        firstDayOfWeek: 1,
        ordinal: function () {
          return "-";
        }
      },
      mode: "single",
      enableTime: true,
      time_24hr: true,
      dateFormat: "d.m.Y H:i",
      minDate: new Date(),
      //maxDate: "today",
      defaultDate: null,
      onClose: (selectedDates, dateStr) => {
        this.validate(this.dates[0]);
      }
    });
    flatpickr(this.dates[0], {
      locale: {
        weekdays: {
          shorthand: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
          longhand: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ]
        },
        months: {
          shorthand: [
            "Sty",
            "Lut",
            "Mar",
            "Kwi",
            "Maj",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Paź",
            "Lis",
            "Gru",
          ],
          longhand: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ]
        },
        rangeSeparator: " do ",
        weekAbbreviation: "tydz.",
        scrollTitle: "Przewiń, aby zwiększyć",
        toggleTitle: "Kliknij, aby przełączyć",
        firstDayOfWeek: 1,
        ordinal: function () {
          return "-";
        }
      },
      mode: "single",
      enableTime: true,
      time_24hr: true,
      dateFormat: "d.m.Y H:i",
      minDate: new Date(),
      //maxDate: "today",
      defaultDate: null,
      onClose: (selectedDates, dateStr) => {
        this.validate(this.dates[0]);
      }
    });
  }
}

export default formApp;
