import '../sass/core.scss';
import '../sass/framework/hd.scss';

import "./global";
import {lazyCSS} from "./class/lazyCSS";
import {lazyImg} from "./class/lazyImg";
import formApp from "./class/form";

const mod = {};

// ======== load core =========
mod.core = () => {
  mod.css = new lazyCSS();
  mod.lazy = new lazyImg('[data-url]');
  mod.form = new formApp();

  let btn_pdf = document.body.querySelector('[data-btnpdf]');
  btn_pdf && addJS('js/html2pdf.bundle.js', {
    id: 'pdf_js', cb: () => {
      btn_pdf && btn_pdf.addEventListener('click', (e) => {
        e.target.style.display = 'none';
        html2pdf(document.body.querySelector('[data-pdf]'), {
          filename: `voucher_${e.target.dataset.date}.pdf`,
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        });
        setTimeout(()=> {
          e.target.style.display = '';
        }, 500);
      })
    }
  });

  addCSS('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
};

!is_observer() ? addJS('js/polyfill.js', {
  cb: mod.core
}) : document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core();
